<template>
  <div class="LessonEntityDetails__Component">
    <FieldInlineText :readOnly="isEntityPage" label="שם התרגול" @onChange="onChange" v-model="model.name" />
    <FieldInlineText :readOnly="isEntityPage" label="כותרת משנה" @onChange="onChange" v-model="model.subTitle" />

    <FieldInlineText :readOnly="isEntityPage" :type="'number'" label="סדר" @onChange="onChange" v-model="model.sortOrder" />

    <FieldInlineSelect :readOnly="isEntityPage" label="קטגוריה" @onChange="onChange" :filterable="true" :clearable="true"
      :optionsAsync="getCategories" v-model="model.categoryId" />

    <FieldInlineSelect :readOnly="isEntityPage" label="סוג תרגול" @onChange="onChange" :filterable="true" :clearable="true" :options="[
      {
        Value: '0',
        Text: 'רגיל'
      },
      {
        Value: 1,
        Text: 'חוק מלא'
      },
      {
        Value: 2,
        Text: 'חוק דינאמי'
      },
      {
        Value: 3,
        Text: 'מיקודית'
      },
      {
        Value: 4,
        Text: 'סימולציה'
      }
    ]" v-model="model.exerciseTypeId" />

    <div class="checkbox-container">
      <b-switch :disabled="isEntityPage" class="ltr" @input="onChange" v-model="model.status">{{
        model.status ? "פעיל" : "לא פעיל"
      }}</b-switch>
    </div>

  </div>
</template>

<script>
// import FieldInlineTextArea from '@/components/Fields/FieldInlineTextArea.vue';
// import FieldInlineDate from '@/components/Fields/FieldInlineDate.vue';
// import FieldInlineDateTime from '@/components/Fields/FieldInlineDateTime.vue';
import FieldInlineText from '@/components/Fields/FieldInlineText.vue';
import FieldInlineSelect from '@/components/Fields/FieldInlineSelect.vue';
import { mapGetters, mapMutations } from 'vuex';
import CoursesService from '../services/CoursesService';

export default {
  name: 'LessonEntityDetails',
  components: {
    FieldInlineText,
    // FieldInlineDate,
    FieldInlineSelect,
    // FieldInlineTextArea,
  },
  props: ['readOnly'],
  data() {
    return {
      model: {
        id: null,
        lessonName: null,
        name: null,
        sortOrder: null,
        lessonId: null,
        categoryName: null,
        categoryId: null,
        exerciseTypeId: null,
        status: null,
        subTitle: null
      },
    };
  },
  computed: {
    ...mapGetters('exercise', [
      'form',
    ]),
    changesAmount() {
      return Object.keys(this.diff(this.model, this.form)).length;
    },
    isEntityPage() {
      return false;
      return !!this.$route.params.id;
    },
  },
  created() {
    this.model = { ...this.model, ...this.form };
    if (!this.model.exerciseTypeId) this.model.exerciseTypeId = '0';
  },
  methods: {
    diff(o1, o2) {
      const diff = Object.keys(o2).reduce((diff1, key) => {
        if (o1[key] && typeof o1[key].getMonth === 'function') {
          if (new Date(o1[key]).toString() === new Date(o2[key]).toString()) return diff1;
        }
        if (typeof o1[key] === 'number') {
          if (parseInt(o1[key], 10) === parseInt(o2[key], 10)) return diff1;
        }
        if (o1[key] && typeof o1[key] === 'object' && typeof o1[key].getMonth !== 'function') {
          if (o1[key]?.Id === o2[key]?.Id) return diff1;
        }
        if (o1[key] === o2[key]) return diff1;
        return {
          ...diff1,
          [key]: o2[key],
        };
      }, {});

      return diff;
    },
    getCategories() {
      return () =>
        CoursesService.getCategories().then((r) =>
          r.data.map((i) => ({ Value: i.id, Text: i.name }))
        );
    },
    onChange() {
      this.$emit('onFormChange', this.changesAmount);
    },
    cancel() {
      this.model = { ...this.model, ...this.form };
    },
    save() {
      this.$emit('onFormSave', {
        model: this.model,
        cancel: this.cancel,
        setRestorePoint: this.setRestorePoint,
      });
    },
    setRestorePoint() {
      this.storeLessonDetails(this.model);
    },
    refresh() {
      this.model = { ...this.model, ...this.form };
    },
    ...mapMutations('exercise', [
      'storeLessonDetails',
    ]),
  },
};
</script>

<style lang="scss" scoped>
.checkbox-container {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}
</style>
